import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FilterBuilderOutput,
  FilterBuilderParam,
  translateFilterBuilderToLegacy
} from 'src/app/models/filter-builder';
import { ItemSearchOptionField } from '../../filter-list/models/filterClasses';
import {
  CustomReport,
  DataSetField,
  ReportDataSetFieldDataType
} from 'src/app/models/custom-report';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'wm-custom-report-filter-input',
  templateUrl: './custom-report-filter-input.component.html',
  styleUrl: './custom-report-filter-input.component.css'
})
export class CustomReportFilterInputComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if(this.form) {
      this.form.removeControl('reqGrp');
    }
  }
  @Input()
  public report: CustomReport;
  public params: FilterBuilderParam[] = [];
  public filters: ItemSearchOptionField[];
  @Output()
  filtersChanged: EventEmitter<any> = new EventEmitter();
  requiredFilterInput: DataSetField[];
  reqGrp: UntypedFormGroup;
  @Input()
  form: UntypedFormGroup;

  ngOnInit(): void {
    this.reqGrp = new UntypedFormGroup({});

    if(this.form) {
      this.form.addControl('reqGrp', this.reqGrp);
    }

    if (this.report.filterConfig) {
      const parms = this.report.filterConfig.fields.map(f => {
        if (f.dataType === ReportDataSetFieldDataType.Date) {
          return {
            name: f.label,
            id: f.name,
            inputType: 'date',
            types: ['range']
          };
        } else if (f.dataType === ReportDataSetFieldDataType.String) {
          return {
            name: f.label,
            id: f.name,
            inputType: 'text',
            types: ['range'],
            options:
              f.availableFilterItems && f.availableFilterItems.length > 0
                ? f.availableFilterItems.map(afi => {
                    return {
                      name: afi.text,
                      value: afi.value
                    };
                  })
                : null
          };
        }
      });

      // setup form controls for search filters
      this.requiredFilterInput = this.report.filterConfig.fields.filter(
        f => f.requireInput
      );

      this.requiredFilterInput.forEach(f => {
        this.reqGrp.addControl(
          f.name,
          new UntypedFormControl(f.defaultSearchValue, Validators.required)
        );
        this.reqGrp.controls[f.name].markAsDirty();
      });

      this.params = parms;
    }
  }

  filtersChangedHandler(filters: FilterBuilderOutput) {
    this.filters = translateFilterBuilderToLegacy(filters);

    const keys = Object.keys(filters);

    const valKeys = Object.keys(this.reqGrp.controls);

    valKeys.forEach(k => {
      this.reqGrp.controls[k].setValue(null);
      this.reqGrp.controls[k].markAsDirty();
      this.reqGrp.controls[k].markAsTouched();
    });

    keys.forEach(k => {
      const ctl = this.reqGrp.controls[k];

      if (ctl) {
        ctl.setValue('populated');
        ctl.markAsDirty();
        ctl.markAsTouched();
      }
    });

    this.filtersChanged.emit(this.filters);
  }
}

import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { AuthenticationService } from './../../../services/authentication.service';
import {
  DataService,
  ActivityFactory,
  ActivityUtilities,
  Utilities,
  SecurityService
} from 'src/app/services';
import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowService, WorkflowContextService } from '../../../services';
import {
  User,
  WorkflowApplication,
  ApplicationStatus,
  Actions,
  WorkflowType
} from 'src/app/models';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { PaymentRequest } from 'src/app/models/activities/payment-activity';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { GPErrorHandler } from 'src/app/services/gp-error';
import { Subscription } from 'rxjs';
import { EditApplicationNumberComponent } from 'src/app/components/workflow/edit-application-number/edit-application-number.component';

@Component({
  selector: 'wm-application-summary',
  templateUrl: './application-summary.component.html',
  styleUrls: ['./application-summary.component.css']
})
export class ApplicationSummaryComponent
  implements OnInit, AfterViewInit, OnDestroy {
  submitter: User;
  applicationId: string;
  application: WorkflowApplication;
  applicationUrl: string;
  currentActivity: any;
  summaryActivities: Activity<ActivityModel>[];
  paymentRequests: PaymentRequest[];
  printMode = false;
  showUnDiscardButton = false;
  showReopenApplicationButton = false;
  isTestApplication: boolean;
  totalPaid: number;
  fees = [];
  isEntitledToUnvoid = false;
  isChildApplication = false;
  registrationId: string;
  applicationWorkflowType: WorkflowType;
  WorkflowType = WorkflowType;
  applicationStatus: ApplicationStatus;
  ApplicationStatus = ApplicationStatus;
  canViewVersion = false;
  canEditCompletedApplication = false;
  canReopenApplication = false;
  clientSub: Subscription;
  @ViewChild('RecalculateDescription', { static: true })
  recalculateDescriptionModal: ModalConfirmComponent;
  @ViewChild('UndiscardApplication')
  undiscardApplicationModal: ModalConfirmComponent;
  @ViewChild('reopenApplication')
  reopenApplicationModal: ModalConfirmComponent;
  canEditApplicationNumber = false;
  @ViewChild('editAppNumber', { static: false })
  editAppNumber: EditApplicationNumberComponent;

  apiBase = Utilities.getRootURL();

  constructor(
    private _service: DataService,
    private _route: ActivatedRoute,
    private _workflowSvc: WorkflowService,
    public context: WorkflowContextService,
    private _router: Router,
    private _toastr: ToastrService,
    private _securitySvc: SecurityService,
    @Inject(DOCUMENT) private document: Document,
    private _errorHandler: GPErrorHandler
  ) {}
  ngOnDestroy(): void {
    if (this.clientSub) {
      this.clientSub.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    if (this.printMode) {
      this.document.body.style.backgroundColor = 'white';
    }
  }

  ngOnInit() {
    const init = () => {
      this._securitySvc
        .isLoginEntitled(Actions.VIEW_APPLICATION_VERSION)
        .subscribe(r => {
          this.canViewVersion = r;
        });

      if (!this.printMode) {
        this._securitySvc
          .isLoginEntitled(Actions.EDIT_COMPLETED_APPLICATIONS)
          .subscribe(r => {
            this.canEditCompletedApplication = r;
          });

        this._securitySvc
          .isLoginEntitled(Actions.REOPEN_COMPLETED_APPLICATION)
          .subscribe(r => {
            this.canReopenApplication = r;
          });

        this._securitySvc
          .isLoginEntitled(Actions.WORKFLOW_APPLICATIONS_EDIT)
          .subscribe(r => {
            this.isEntitledToUnvoid = r;
          });

        this._securitySvc
          .isLoginEntitled(Actions.EDIT_APPLICATION_NUMBER)
          .subscribe(r => {
            this.canEditApplicationNumber = r;
          });
      }
    };

    this._route.params.subscribe(({ applicationId }) => {
      this.applicationUrl = `${window.location.origin}/application/workflow-application-summary/${applicationId}`;
      this.applicationId = applicationId;

      this._route.data.subscribe(d => {
        this.printMode = d.printMode;

        this.getApplication();
      });
    });

    if (this.context.client) {
      init();
    } else {
      this.clientSub = this.context.client$.subscribe(client => {
        if (!client) {
          return;
        }
        init();
      });
    }
  }

  editApplicationNumber() {
    this.editAppNumber.open();
  }

  canUnVoid(): boolean {
    return (
      this.application.status === ApplicationStatus.Voided &&
      this.isEntitledToUnvoid
    );
  }

  returnToParent() {
    this._router.navigate([
      'application',
      'workflow-application',
      this.application.parentApplicationId
    ]);
  }

  startRenewalApplication() {
    this._workflowSvc
      .startRegistrationRenewal(this.registrationId)
      .subscribe(result => {
        this._router.navigate([
          '/application/workflow-application',
          result.applicationId
        ]);
      });
  }

  unVoid() {
    this._workflowSvc.unVoidApplication(this.applicationId).subscribe(r => {
      this._toastr.success('Application Un-Voided');
      this._router.navigate([
        'application',
        'workflow-application',
        this.applicationId
      ]);
    });
  }
  getApplication() {
    this._service
      .getApplicationSummary(this.applicationId)
      .subscribe(appSummary => {
        this.context.applicationId$.emit(this.applicationId);
        if (appSummary) {
          this.application = appSummary.application;
          this.isTestApplication = appSummary.application.isTestApplication;
          this.isChildApplication = appSummary.application.hasParentApplication;
          this.registrationId = appSummary.registrationId;
          this.applicationWorkflowType =
            appSummary.application.workflowVersion.workflow.workflowType;
          this.applicationStatus = appSummary.application.status;
          this.showUnDiscardButton =
            appSummary.application.status === ApplicationStatus.Discarded;
          this.showReopenApplicationButton =
            (
              appSummary.application.status === ApplicationStatus.Completed
              || appSummary.application.status === ApplicationStatus.Rejected
            )
          const activities = ActivityUtilities.convertActivitiesJsonToClasses(
            appSummary.completedActivities
          );
          // convert the dictionary to an array
          const convertedActivities = Object.keys(activities).map(
            key => activities[key]
          );
          this.summaryActivities = convertedActivities;

          this.fees = appSummary.fees;
          this.totalPaid = appSummary.totalPaid;
          this.submitter = appSummary.submitter;
        }
        this._service
          .getPaymentRequests(this.applicationId)
          .subscribe(requests => {
            this.paymentRequests = requests;
          });
      });
  }

  exitTestApplication() {
    this._workflowSvc.cleanupTestApplication(this.applicationId).subscribe(
      a => {
        this.context.applicationId$.emit(null);
        this.context.viewAsRole$.emit(null);
        this._router.navigate([this.context.applicationReturnURL]);
      },
      err => {
        this._errorHandler.handleError(err);
      }
    );

    return false;
  }

  openApplicationWorkflowBuilder() {
    const workflowId = this.application.workflowVersion.workflowId;
    const versionId = this.application.workflowVersionId;

    this._router.navigate([
      '/admin/workflow-builder/',
      workflowId,
      versionId,
      this.application.id
    ]);
  }

  undiscardApplication(force: boolean) {
    if (!force) {
      this.undiscardApplicationModal.open();
    } else {
      this._workflowSvc
        .undiscardApplication(this.applicationId)
        .subscribe(app => {
          this.undiscardApplicationModal.cancel();
          this._router.navigate([
            '/application/workflow-application/',
            this.applicationId
          ]);
        });
    }
  }

  setApplicationBackToInProgress(force: boolean) {
    if (!force) {
      this.reopenApplicationModal.open();
    } else {
      this._workflowSvc
        .reopenApplication(this.applicationId)
        .subscribe(result => {
          if (result) {
            this.reopenApplicationModal.cancel();
            this._router.navigate([
              '/application/workflow-application/',
              this.applicationId
            ]);
          } else {
            this._toastr.error('Failed to set application to In Progress.  Please contact support if this error persists.');
          }
        });
    }
  }


  recalculateApplicationDescription(force: boolean) {
    if (!force) {
      this.recalculateDescriptionModal.open();
    } else {
      this._workflowSvc
        .recalculateApplicationDescription(this.applicationId)
        .subscribe(appDescription => {
          this.application.description = appDescription;
          this.recalculateDescriptionModal.cancel();
        });
    }
  }
}

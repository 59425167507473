import { startWith } from 'rxjs/operators';
import { ActivityStatus } from './../../../models/activities/activity';
import { ToastrService } from 'ngx-toastr';
import { RichTextDataEntity } from 'src/app/models/data-entities/rich-text-data-entity';
import {
  Component,
  OnInit,
  Input,
  Inject,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import {
  ActivityModel,
  Activity,
  FormActivity
} from '../../../models/activities';
import { UntypedFormGroup } from '@angular/forms';
import { WorkflowApplication, Document, Actions } from 'src/app/models';
import {
  DocumentService,
  WorkflowService,
  SecurityService,
  ValidationService,
  DataEntityFactory,
  ActivityFactory,
  ActivityUtilities
} from 'src/app/services';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { WorkflowApplicationActivityDataAudit } from 'src/app/models/workflow-application-activity-data-audit';
import { WorkflowApplicationActivityAuditDetails } from 'src/app/models/workflow-application-activity-audit-details';
import { DataEntity } from 'src/app/models/data-entities';
import { ActivityViewComponent } from 'src/app/components/workflow/activities/activity-editor/activity-view/activity-view.component';

@Component({
  selector: 'wm-application-readonly',
  templateUrl: './application-readonly.component.html',
  styleUrls: ['./application-readonly.component.css']
})
export class ApplicationReadonlyComponent implements OnInit {
  @Input() applicationId: string;
  @Input() application: WorkflowApplication;
  @Input() activities: Activity<ActivityModel>[];
  form: UntypedFormGroup;
  @Input() isTestApplication: boolean;
  externalNotesDE: RichTextDataEntity;
  internalNotesDE: RichTextDataEntity;
  canEditNotes: boolean;
  canEditActivity: boolean;
  @ViewChild('EditCompletedActivity', { static: true })
  private editCompletedActivity: ModalConfirmComponent;
  @ViewChild('ActivityHistory', { static: true })
  private activityHistory: ModalConfirmComponent;
  ActivityStatus = ActivityStatus;
  @ViewChild('editActivityInput', { static: false })
  private editActivityInput: ActivityViewComponent;
  @ViewChildren('activityViewItem')
  private activityViewItems: QueryList<ActivityViewComponent>;
  @ViewChild('tabs', { static: false })
  showRemove = false;

  internalDocuments: Document[];
  externalDocuments: Document[];
  activityForm: UntypedFormGroup;
  editingActivity: Activity<ActivityModel>;
  activityHistoryItems: WorkflowApplicationActivityDataAudit[] = null;
  historyLoading = false;
  auditLoading: { [key: string]: boolean } = {};
  auditData: { [key: string]: WorkflowApplicationActivityAuditDetails } = {};
  activeTabId: string;

  auditEntities: {
    [key: string]: {
      [key: string]: {
        oldEntity: DataEntity;
        newEntity: DataEntity;
        templateCode: string;
      };
    };
  } = {};

  constructor(
    private _workflowSvc: WorkflowService,
    private _securitySvc: SecurityService,
    private _toastrSvc: ToastrService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({});
    if (this.application) {
      this._securitySvc
        .isLoginEntitled(Actions.WORKFLOW_APPLICATIONS_EDIT)
        .subscribe(result => {
          this.canEditNotes = result;
        });
      this._securitySvc
        .isLoginEntitled(Actions.EDIT_COMPLETED_APPLICATIONS)
        .subscribe(result => {
          this.canEditActivity = result;
        });
    }
  }

  saveNotes() {
    const internalNotes: string = this.application.internalNotes;
    const externalNotes: string = this.application.externalNotes;

    this._workflowSvc
      .saveWorkflowApplicationNotes(
        this.applicationId,
        internalNotes,
        externalNotes
      )
      .subscribe(() => {
        this._toastrSvc.success('Application Notes Saved');
      });
  }

  editActivity(activity: Activity<ActivityModel>) {
    if (this.editActivityInput && this.editActivityInput.view) {
      this.editActivityInput.view.destroy();
    }

    this.activityForm = new UntypedFormGroup(
      ValidationService.createValidationGroup(activity)
    );

    // clone the activity so it doesn't get udpated by the input if canceled
    const clonedActivity: Activity<ActivityModel> = ActivityUtilities.convertActivitiesJsonToClasses(
      [ActivityFactory.createActivity(activity, activity)]
    )[0];

    this.editingActivity = clonedActivity;
    this.editCompletedActivity.moduleId = this.editingActivity.id;
    this.editCompletedActivity.open();
    this.ref.detectChanges();
  }

  cancelEdit() {
    if (this.editActivityInput && this.editActivityInput.view) {
      this.editActivityInput.view.destroy();
    }
    this.editingActivity = null;
    this.activityForm.reset();
  }

  closeEdit() {
    this.editCompletedActivity.cancel();
  }

  goNext() {
    this.editCompletedActivity.accept();
  }

  public updateActivity(evt?: any) {
    const editAct = this.editingActivity;
    this.editActivityInput.persistChildComponent().then(value => {
      this._workflowSvc
        .saveWorkflowActivity(this.applicationId, editAct, true)
        .subscribe(() => {
          this._workflowSvc
            .navigateToActivity(
              this.applicationId,
              editAct.activityDataId
            )
            .subscribe(resp => {
              const act = resp.activity;

              if (editAct && this.activities) {
                const listInActivity = this.activities
                  .filter(a => a != null)
                  .find(
                    a => a.activityDataId == editAct.activityDataId
                  );

                if (listInActivity) {
                  const editIdx = this.activities.indexOf(listInActivity);

                  if (editIdx > -1 && act) {
                    this.activeTabId = act.activityDataId;
                    this.activities[editIdx] = act;

                    if (this.activityViewItems) {
                      const viewComp = this.activityViewItems.find(
                        f =>
                          f.activity &&
                          f.activity.activityDataId ==
                          editAct.activityDataId
                      );

                      if (viewComp) {
                        viewComp.loadView(act);
                      }
                    }
                  }
                }
              }

              this.closeEdit();
            });
        });
    });
  }

  viewActivityHistory(activity: Activity<ActivityModel>) {
    this.historyLoading = true;

    this.activityHistory.moduleId = activity.id;
    this.activityHistory.title = activity.model.screenName + ' History';
    this.activityHistory.open();

    this._workflowSvc
      .getActivityDataAudit(this.applicationId, activity.activityDataId)
      .subscribe(result => {
        this.historyLoading = false;
        this.activityHistoryItems = result;
      });
  }

  closeHistory() {
    this.activityHistoryItems = null;
  }

  historyExpand(e) {
    if (e.nextState == true) {
      this.expandAudit(e.panelId); // id of the panel is the audit id
    }
  }

  auditEntityKeys(auditId) {
    if (this.auditEntities && this.auditEntities[auditId]) {
      return Object.keys(this.auditEntities[auditId]);
    } else {
      return [];
    }
  }

  expandAudit(auditId: string) {
    this.auditLoading[auditId] = true;
    this.auditEntities[auditId] = null;

    // get full details for the audit record
    this._workflowSvc
      .getActivityDataAuditDetails(this.applicationId, auditId)
      .subscribe(details => {
        this.auditLoading[auditId] = false;
        this.auditData[auditId] = details;

        // display the view of the activity if there is a newValue for the activity
        if (details.activityInfo.newValue) {
        }

        if (details.entityDetails) {
          const entityDEs = {};
          details.entityDetails.forEach(ed => {
            entityDEs[ed.templateCode] = {
              oldEntity: ed.oldEntity,
              newEntity: ed.newEntity,
              templateCode: ed.templateCode
            };
          });

          this.auditEntities[auditId] = entityDEs;
        }
      });
  }
}

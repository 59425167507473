import { Component, Input, OnInit } from '@angular/core';
import { ForteCheckoutPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/fortecheckout/fortecheckout-payment-processor-account-configuration';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-account-config-forte-checkout',
  templateUrl: './payment-account-config-forte-checkout.component.html',
  styleUrl: './payment-account-config-forte-checkout.component.css'
})
export class PaymentAccountConfigForteCheckoutComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: ForteCheckoutPaymentProcessorAccountConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
    this.showUsername = false;
    this.showPassword = false;
  }

  ngOnInit() {
    this.addValidation(
      'apiAccessId',
      Validators.required,
      this.config.apiAccessId
    );
    this.addValidation(
      'apiSecureKey',
      Validators.required,
      this.config.apiSecureKey
    );
    this.addValidation(
      'locationId',
      Validators.nullValidator,
      this.config.locationId
    );
  }
}

import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  TemplateRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import {
  PaymentConfirmationStatus,
  PaymentActivity,
  PaymentMethods,
  PaymentRequest
} from '../../../../../models/activities';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  UntypedFormControl
} from '@angular/forms';
import {
  WorkflowService,
  WorkflowPaymentRequest,
  Utilities,
  SecurityService,
  PaymentRequestResult
} from '../../../../../services';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Actions } from 'src/app/models';
import { Router } from '@angular/router';

@Component({
  selector: 'wm-payment-activity-input',
  templateUrl: './payment-activity-input.component.html',
  styleUrls: ['./payment-activity-input.component.css']
})
export class PaymentActivityInputComponent extends ActivityView
  implements OnInit, AfterViewInit, OnDestroy {
  activity: PaymentActivity;
  @ViewChild('paymentForm', { read: ElementRef, static: true })
  paymentForm: ElementRef;
  showManualPayment = false;
  hasIntegration: boolean;
  manualForm: UntypedFormGroup;
  payments: PaymentRequest[];
  apiBase = Utilities.getRootURL();
  processing = false;
  isGlobalAdmin = false;
  viewingAsApplicantInTest = false;
  @ViewChild('paymentButton', { static: false })
  paymentButton: TemplateRef<any>;
  @ViewChild('forteCheckoutPaymentButton', { static: false })
  forteCheckoutPaymentButton: TemplateRef<any>;
  @ViewChild('partialPaymentAmount', { static: false })
  partialPaymentAmount: HTMLElement;

  PaymentMethods = PaymentMethods;
  PaymentConfirmationStatus = PaymentConfirmationStatus;
  signing: boolean = false;
  paymentButtonTemplate: string;

  transactionNumberValidators: any[];

  get lineItems() {
    if (this.activity.model.fees) {
      return this.activity.model.fees.map(fee => ({
        name: fee.label,
        amount: fee.formulaValue
      }));
    }

    return null;
  }

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService,
    private _fb: UntypedFormBuilder,
    private _ref: ChangeDetectorRef,
    private _toastrSvc: ToastrService
  ) {
    super();
  }
  ngOnDestroy(): void {
    if (this.payAmountSub) {
      this.payAmountSub.unsubscribe();
    }

    const btn = document.getElementById('fortePaymentButton');

    if (btn) {
      btn.removeAllListeners('click');
    }

    window['callback'] = null;
  }

  scriptLoaded: boolean = false;
  scriptLoading: boolean = false;

  startEditingPartialAmount() {
    if (
      this.activity.model.paymentButtonParameters &&
      this.activity.model.paymentButtonParameters.scriptURL
    ) {
      this.signing = true;
    }
  }

  loadPaymentButtonScript() {
    if (
      this.activity.model.paymentButtonParameters &&
      this.activity.model.paymentButtonParameters.scriptURL &&
      !this.scriptLoaded &&
      !this.scriptLoading
    ) {
      this.scriptLoading = true;
      this.loadScript(this.activity.model.paymentButtonParameters.scriptURL);
      this.scriptLoaded = true;
      this.scriptLoading = false;
    }
  }

  ngAfterViewInit(): void {
    if (this.activity.model.paymentButtonParameters) {
      // this is here for the forte checkout integration because the button needs access to this method on the window.
      window['callback'] = this.callback.bind(this);
    }
  }

  enableNext() {
    // disable the next button if they still have money due
    if (this.activity.model.totalDue > 0) {
      this.showNext.emit(false);
    } else if (this.activity.canGoNext) {
      this.showNext.emit(true);
    }
  }

  preparedRequests = {};
  confirmedRequests = {};

  // callback for ForteCheckout integration
  public callback(e) {
    if (e.data && typeof e.data === 'string') {
      var data = JSON.parse(e.data);

      // disable the button
      if (data.event == 'begin') {
        if (!this.processing) {
          this.processing = true;
          this._ref.detectChanges();
          // don't allow the same request to prepare multiple times. I can't seem to get the Forte Checkout script to release the button when multiple payments are being made
          if (!this.preparedRequests[data.request_id]) {
            this.preparedRequests[data.request_id] = true;
            // create a payment request
            this._workflowSvc
              .prepareWorkflowPayment(
                this.context.applicationId,
                <PaymentActivity>this.activity,
                this.activity.model.paymentButtonParameters.orderNumber || ''
              )
              .subscribe();
          }
        }
      }

      if (data.event == 'abort') {
        this.processing = false;
        this._ref.detectChanges();
      }

      // payment was completed successfully
      if (data.event == 'success') {
        if (!this.confirmedRequests[data.request_id]) {
          this.confirmedRequests[data.request_id] = true;
          this._workflowSvc
            .confirmForteCheckoutPayment(
              this.applicationId,
              this.activity,
              data.order_number,
              e.data
            )
            .subscribe(
              result => {
                if (result) {
                  if (
                    !result.nextActivity ||
                    result.nextActivity.id != this.activity.id
                  ) {
                    this.onRefreshApp.emit();
                  } else {
                    this.activity = result.nextActivity as PaymentActivity;

                    this.enableNext();
                  }
                }
                setTimeout(() => {
                  this.processing = false;
                  this._ref.detectChanges();
                }, 100);
              },
              ex => {
                console.log('errr', ex.message);
                this.processing = false;
                this._ref.detectChanges();
              }
            );
        }
      }
    }
  }

  loadScript(url: string) {
    if (document.getElementById('paymentScript')) {
      document
        .getElementsByTagName('head')[0]
        .removeChild(document.getElementById('paymentScript'));
    }

    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node['id'] = 'paymentScript';
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  payAmountSub: Subscription;

  loadPaymentButton() {
    if (
      this.activity.model.paymentButtonParameters &&
      this.manualForm &&
      this.manualForm.controls['paymentAmount']
    ) {
      this.manualForm.controls['paymentAmount'].setValue(
        this.activity.model.paymentButtonParameters
      );
      this.payAmountSub = this.manualForm.controls[
        'paymentAmount'
      ].valueChanges.subscribe(e => {
        this.signing = true;
        const amount = this.manualForm.controls['paymentAmount'].value;
        if ((amount || 0) > 0) {
          this._workflowSvc
            .getPaymentButtonParameters(
              this.activity.model.paymentButtonParameters.accountId,
              amount
            )
            .subscribe(params => {
              this.activity.model.paymentButtonParameters = params;
              this.signing = false;
            });
        }
      });
    }
  }

  ngOnInit() {
    this.hasIntegration = this.activity.model.hasIntegration;
    this.payments = this.activity.model.payments;

    // only default the payment method if we haven't made a payment already
    // and a payment method hasn't been saved by the user as something besides the server's default of Online
    if (
      this.activity.model.paymentMethod === null ||
      this.activity.model.paymentMethod === PaymentMethods.Online
    ) {
      // default to either Online or Cash, depending on if the payment activity has integration
      this.activity.model.paymentMethod = this.hasIntegration
        ? PaymentMethods.Online
        : PaymentMethods.Cash;
    }

    this.enableNext();

    // check to see if the user is an administrator and show the manual payment options if they are
    this._securitySvc.isAnAdministrator().subscribe(value => {
      this.showManualPayment = value;

      this.manualForm = this._fb.group({});

      if (this.showManualPayment) {
        this.transactionNumberValidators = [Validators.required];

        this.manualForm.addControl(
          'paymentMethod',
          new UntypedFormControl('', Validators.required)
        );

        this.manualForm.addControl(
          'transactionNumber',
          new UntypedFormControl('', this.transactionNumberValidators)
        );

        this.manualForm.controls['paymentMethod'].valueChanges.subscribe(
          result => {
            if (result == '2') {
              this.manualForm.controls['transactionNumber'].setValidators(
                this.transactionNumberValidators
              );
            } else {
              this.manualForm.controls['transactionNumber'].clearValidators();
            }

            if (result == '3') {
              this.loadPaymentButtonScript();
            }

            this.manualForm.controls[
              'transactionNumber'
            ].updateValueAndValidity();
          }
        );
      } else {
        this.manualForm = this._fb.group({});
        this.loadPaymentButtonScript();
      }

      if (this.activity.model.allowMultiplePayments) {
        this.manualForm.addControl(
          'paymentAmountOption',
          new UntypedFormControl('', Validators.required)
        );

        this.manualForm.addControl(
          'paymentAmount',
          new UntypedFormControl('', {
            updateOn: 'blur',
            validators: [
              Validators.required,
              Validators.min(0.01),
              Validators.max(this.activity.model.totalDue)
            ]
          })
        );
      }

      if (!this.activity.model.allowMultiplePayments) {
        this.activity.model.paymentAmount = this.activity.model.totalDue;
      }

      this._ref.detectChanges();

      if (this.activity.model.allowMultiplePayments) {
        this.manualForm.controls['paymentAmount'].disable();
      }

      this.loadPaymentButton();
    });

    this._securitySvc
      .isLoginEntitled(Actions.DO_ANYTHING)
      .subscribe(isEntitled => {
        this.isGlobalAdmin = isEntitled;
      });

    // in test applications, only manual payment methods should be available to non-global-admin users
    if (this.isTestApplication && !this.isGlobalAdmin) {
      // default the payment method to Cash (Online option is removed in template)
      this.activity.model.paymentMethod = PaymentMethods.Cash;

      if (
        this.context.viewAsRole.id.toLowerCase() ===
        SecurityService.APPLICANT_ROLE.id.toLowerCase()
      ) {
        // disable the Make Payment button
        this.viewingAsApplicantInTest = true;
      }
    }
  }

  paymentOption: string = null;

  payFull() {
    this.paymentOption = 'fullAmount';
    this.activity.model.paymentAmount = this.activity.model.totalDue;
    this.manualForm.controls['paymentAmount'].disable();
  }

  payPartial() {
    this.paymentOption = 'partialAmount';
    this.manualForm.controls['paymentAmount'].enable();
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  submitPayment() {
    // An admin could have saved this activity with some kind of manual payment.
    // Then a non-admin (applicant) can come to the activity and be presented with
    // the Make Payment button because this has an integration.
    // In that situation, make sure the payment request is set to Online
    if (this.hasIntegration && !this.showManualPayment) {
      this.activity.model.paymentMethod = PaymentMethods.Online;
    }

    const form: HTMLFormElement = <HTMLFormElement>(
      this.paymentForm.nativeElement
    );
    const that = this;
    if (this.manualForm.invalid) {
      this.validateAllFormFields(this.manualForm);
    } else {
      this.processing = true;
      const form: HTMLFormElement = <HTMLFormElement>(
        this.paymentForm.nativeElement
      );
      const that = this;

      // null out the payments if it exists because of serialization issues
      this.payments = null;

      this._workflowSvc
        .prepareWorkflowPayment(
          this.context.applicationId,
          <PaymentActivity>this.activity
        )
        .subscribe((request: WorkflowPaymentRequest) => {
          if (request && request.result === PaymentRequestResult.Success) {
            if ((request.url || '') !== '') {
              request.execute(form);
            } else {
              that.goNext.emit(true);
            }
          } else {
            this.processing = false;
            let tstrMsg: Observable<any>;

            if (request.reloadActivity) {
              tstrMsg = this._workflowSvc
                .navigateToActivity(
                  this.context.applicationId,
                  this.activity.activityDataId
                )
                .pipe(
                  map(resp => {
                    const a = resp.activity;

                    this.activity = a as PaymentActivity;
                    return a;
                  })
                );
            } else {
              tstrMsg = of(null);
            }

            tstrMsg.subscribe(d => {
              if (this.activity) {
                this.enableNext();
              }

              this._toastrSvc.error(
                request && request.errorMessage,
                `Payment Failed to submit`
              );
            });
          }
        });
    }
  }
}

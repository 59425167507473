import { Injectable, EventEmitter } from '@angular/core';
import 'rxjs';

import {
  Workflow,
  Role,
  User,
  Client,
  ApplicationConfiguration,
  WorkflowApplication
} from '../models';
import { Activity, ActivityModel } from '../models/activities';
import { DataEntity } from '../models/data-entities';
import { Utilities, ActivityUtilities } from './';
import { ActivityFactory, WorkflowService } from './workflow.service';
import { JsonNetDecycle } from './utilities/json-net-decycle';

@Injectable()
export class WorkflowContextService {
  client$: EventEmitter<Client> = new EventEmitter<Client>();
  user$: EventEmitter<Partial<User>> = new EventEmitter<Partial<User>>();
  isLoggedIn$: EventEmitter<boolean> = new EventEmitter<boolean>();
  appConfiguration$: EventEmitter<ApplicationConfiguration> = new EventEmitter<
    ApplicationConfiguration
  >();
  workflow$: EventEmitter<Workflow> = new EventEmitter<Workflow>();
  activeActivity$: EventEmitter<Activity<ActivityModel>> = new EventEmitter<
    Activity<ActivityModel>
  >();
  previewActivity$: EventEmitter<Activity<ActivityModel>> = new EventEmitter<
    Activity<ActivityModel>
  >();
  viewAsRole$: EventEmitter<Role> = new EventEmitter<Role>();
  authToken$: EventEmitter<string> = new EventEmitter<string>();
  authTokenExpiration$: EventEmitter<number> = new EventEmitter<number>();
  refreshToken$: EventEmitter<string> = new EventEmitter<string>();
  applicationId: string;
  applicationId$: EventEmitter<string> = new EventEmitter<string>();
  applicationReturnURL$: EventEmitter<string> = new EventEmitter<string>();
  buildApplicationReturnURL$: EventEmitter<string> = new EventEmitter<string>();
  returnUrl$: EventEmitter<string> = new EventEmitter<string>();
  needsReload$: EventEmitter<boolean> = new EventEmitter<boolean>();
  previousSystemDataWorkflowId$: EventEmitter<string> = new EventEmitter<
    string
  >();

  _suspendPersist = false;

  authToken: string;
  authTokenExpiration: number;
  refreshToken: string;
  client: Client;
  user: Partial<User>;
  isLoggedIn: boolean;
  appConfiguration: ApplicationConfiguration;
  workflow: Workflow;
  activeActivity: Activity<ActivityModel>;
  previewActivity: Activity<ActivityModel>;
  viewAsRole: Role;
  applicationReturnURL: string;
  buildApplicationReturnURL: string;
  returnUrl: string;
  needsReloaded: boolean;
  versionHash: string;
  hasPreviousSystemData: boolean;
  previousSystemDataWorkflowId: string;
  isProduction: boolean;

  persistContext() {
    if (!this._suspendPersist) {
      try {
        const decycled = JsonNetDecycle.decycle({
          client: this.client,
          user: this.user,
          isLoggedIn: this.isLoggedIn,
          appConfiguration: this.appConfiguration,
          authToken: this.authToken,
          authTokenExpiration: this.authTokenExpiration,
          refreshToken: this.refreshToken,
          viewAsRole: this.viewAsRole,
          testApplicationReturnURL: this.applicationReturnURL,
          buildApplicationReturnURL: this.buildApplicationReturnURL,
          returnUrl: this.returnUrl,
          previousSystemDataWorkflowId: this.previousSystemDataWorkflowId
        });
        localStorage.setItem('context', JSON.stringify(decycled));
      } catch (ex) {
        // eat any errors that occurs for troubleshooting
        console.log('error when persisting context', ex);
      }
    }
  }

  checkVersion(serverVersionHash: string) {
    if (
      this.versionHash &&
      serverVersionHash &&
      this.versionHash != serverVersionHash
    ) {
      this.needsReload$.emit(true);
    }
  }

  mapWorkflowActivities(workflow: Workflow): Workflow {
    return ActivityUtilities.convertWorkflowJsonToClasses(workflow);
  }

  searchWorkflowActivities(
    workflow: Workflow,
    searchActivity: Activity<ActivityModel>
  ): Activity<ActivityModel> {
    const flatActivities = ActivityUtilities.flattenActivities(
      workflow.version.graph.getActivities(workflow.designStatus)
    );

    let activity = null;

    activity = flatActivities.find(a => a.id === searchActivity.id);

    if (!(activity instanceof Activity)) {
      activity = ActivityFactory.createActivity(activity, activity);
    }

    return activity;
  }

  restoreContext() {
    try {
      this._suspendPersist = true;
      const context = JsonNetDecycle.retrocycle(
        JSON.parse(localStorage.getItem('context'))
      );

      this.client$.next(context.client);
      this.user$.next(context.user);
      this.isLoggedIn$.next(context.isLoggedIn);
      this.appConfiguration$.next(context.appConfiguration);
      this.authToken$.next(context.authToken);
      this.authTokenExpiration$.next(context.authTokenExpiration);
      this.refreshToken$.next(context.refreshToken);
      this.applicationReturnURL$.next(context.testApplicationReturnURL);
      this.buildApplicationReturnURL$.next(context.buildApplicationReturnURL);
      this.returnUrl$.next(context.returnUrl);
      this.previousSystemDataWorkflowId$.next(
        context.previousSystemDataWorkflowId
      );

      this.viewAsRole$.next(context.viewAsRole);
      this._suspendPersist = false;
      this.persistContext();
    } catch (ex) {
      this.isLoggedIn$.next(false);
      this._suspendPersist = false;
    }
  }

  constructor() {
    this.client$.subscribe(client => {
      if (client && client.adminRole) {
        client.adminRole.members = null;
      }
      this.client = client;
      this.persistContext();
    });

    this.user$.subscribe(user => {
      this.user = user;
      this.persistContext();
    });

    this.isLoggedIn$.subscribe(value => {
      this.isLoggedIn = value;
      this.persistContext();
    });

    this.appConfiguration$.subscribe(appConfig => {
      this.appConfiguration = appConfig;
      this.persistContext();
    });

    this.workflow$.subscribe(workflow => {
      if (workflow && workflow.adminRole) {
        workflow.adminRole.members = null;
      }
      this.workflow = workflow;
      this.persistContext();
    });

    this.activeActivity$.subscribe(activity => {
      if (activity && activity.model.responsibleRole) {
        activity.model.responsibleRole.members = null;
      }
      this.activeActivity = activity;
      this.persistContext();
    });

    this.previewActivity$.subscribe(previewActivity => {
      this.previewActivity = previewActivity;
      this.persistContext();
    });

    this.viewAsRole$.subscribe(viewAsRole => {
      if (viewAsRole) {
        viewAsRole.members = null;
      }
      this.viewAsRole = viewAsRole;
      this.persistContext();
    });

    this.authToken$.subscribe(authToken => {
      this.authToken = authToken;
      this.persistContext();
    });

    this.authTokenExpiration$.subscribe(authTokenExpiration => {
      this.authTokenExpiration = authTokenExpiration;
      this.persistContext();
    });

    this.refreshToken$.subscribe(refreshToken => {
      this.refreshToken = refreshToken;
      this.persistContext();
    });

    this.applicationId$.subscribe(appId => {
      this.applicationId = appId;
      this.persistContext();
    });

    this.applicationReturnURL$.subscribe(value => {
      this.applicationReturnURL = value;
      this.persistContext();
    });

    this.buildApplicationReturnURL$.subscribe(value => {
      this.buildApplicationReturnURL = value;
      this.persistContext();
    });

    this.returnUrl$.subscribe(value => {
      this.returnUrl = value;
      this.persistContext();
    });

    this.needsReload$.subscribe(value => {
      this.needsReloaded = value;
    });

    this.previousSystemDataWorkflowId$.subscribe(value => {
      this.previousSystemDataWorkflowId = value;
    });

    this.restoreContext();
  }
}

<div class="container">
  <div class="row justify-content-center align-items-center pt-5">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">
            Send Scheduled Emails
          </h6>
          <button
            class="btn btn-raised btn-primary btn-justified"
            (click)="processEmails()"
            [disabled]="processRunning"
          >
            Run
          </button>
          <div *ngIf="processRunning">
            Send Scheduled Email is running...
            <br />
            <app-loader></app-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Document } from 'src/app/models';
import { Registration, RegistrationStatus } from 'src/app/models/registration';
import { RegistrationDocument } from 'src/app/models/registration-document';
import { Requirement } from 'src/app/models/requirement';
import { Utilities, WorkflowService } from 'src/app/services';
import { RenewalCustomFieldListComponent } from '../renewal-custom-field-list/renewal-custom-field-list.component';

@Component({
  selector: 'wm-renewal-details',
  templateUrl: './renewal-details.component.html',
  styleUrls: ['./renewal-details.component.css']
})
export class RenewalDetailsComponent implements OnInit {
  @ViewChild('customFields', { static: false })
  customFields: RenewalCustomFieldListComponent;

  @Input()
  registrationId: string;
  registration: Registration;
  registrationStatus = RegistrationStatus;
  customFieldForm: UntypedFormGroup;
  requirementForm: UntypedFormGroup;
  documents: RegistrationDocument[];
  requirementFields: any[];
  newRequirement: Requirement;
  manualNote: string;
  apiBase = Utilities.getRootURL();
  savingRequirements = false;
  isDestroyed = false;

  constructor(
    private _route: ActivatedRoute,
    private _workflowSvc: WorkflowService,
    private _fb: UntypedFormBuilder,
    private _toastrSvc: ToastrService,
    private _ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.customFieldForm = this._fb.group({});
    this.requirementForm = this._fb.group({});
    this._route.params.subscribe(params => {
      this.registrationId = params.registrationId;
      this.loadRegistration().subscribe();
    });
  }

  ngOnDestroy(): void {
    this._ref.detach();
    this.isDestroyed = true;
  }

  loadRegistration(): Observable<Registration> {
    return this._workflowSvc.getRegistration(this.registrationId).pipe(
      map(registration => {
        if (registration) {
          this.registration = registration;
          this.documents = registration.documents;

          if (
            registration.workflow.requirements &&
            registration.workflow.requirements.length > 0
          ) {
            const requirementIndex = {};
            registration.workflow.requirements.forEach((x, idx) => {
              requirementIndex[x.templateCode] = {
                ...x,
                dataEntityType: x.dataEntityDefinition.dataEntityTypeCode
              };
            });

            if (registration.customFields) {
              this.requirementFields = registration.customFields
                .filter(f => requirementIndex[f.templateCode])
                .map(f => {
                  return {
                    ...f,
                    label: requirementIndex[f.templateCode].label
                  };
                });
            } else {
              this.requirementFields = registration.workflow.requirements.map(
                r => {
                  return {
                    ...r,
                    dataEntityType: r.dataEntityDefinition.dataEntityTypeCode
                  };
                }
              );
            }
          } else {
            this.requirementFields = [];
          }
        } else {
          this._toastrSvc.error(
            "Registration either doesn't exist or you don't have permission to view."
          );
        }
        return registration;
      })
    );
  }

  getAzureDocumentPath(doc: RegistrationDocument) {
    return Utilities.getAzureUrl(doc);
  }

  addNewDocuments(documents: Document[]) {
    const regDocs: RegistrationDocument[] = documents.map(d => {
      const doc = new RegistrationDocument();

      doc.documentName = d.name;
      doc.documentPath = d.path;

      return doc;
    });

    this._workflowSvc
      .saveRegistrationDocuments(this.registrationId, regDocs)
      .subscribe(docs => {
        this.documents = docs;
        this._toastrSvc.success('Document(s) Saved');
      });
  }

  loadDocuments() {
    this._workflowSvc
      .getRegistrationDocuments(this.registrationId)
      .subscribe(docs => {
        this.documents = docs;
      });
  }

  deleteDocument(documentId: string) {
    this._workflowSvc.deleteRegistrationDocument(documentId).subscribe(
      () => {
        this._toastrSvc.success('Document Deleted');
        this.loadDocuments();
      },
      err => {
        console.error(err);
      }
    );
  }

  saveDocument(document: RegistrationDocument) {
    this._workflowSvc.saveRegistrationDocument(document).subscribe(doc => {
      this._toastrSvc.success('Document Saved');
    });
  }

  detectChanges() {
    if (this._ref && !this.isDestroyed) {
      this._ref.detectChanges();
    }
  }

  save() {
    const registration = { ...this.registration };

    registration.customFields = this.customFields.persistValues();

    // we don't need to send all of these across the web.
    registration.documents = [];
    registration.customFields.forEach(field => {
      field.registration = null;
    });

    this.savingRequirements = true;
    this._workflowSvc.saveRegistration(registration).subscribe(
      () => {
        this.loadRegistration().subscribe(() => {
          this._toastrSvc.success('Saved!');
          this.savingRequirements = false;
        });
      },
      err => {
        this._toastrSvc.error(
          'Your changes were not saved.',
          'Something went wrong!'
        );
        this.savingRequirements = false;
      }
    );
  }

  viewReceipt() {
    window.event.preventDefault();
  }

  viewCertificate() {
    window.event.preventDefault();
  }

  addNote() {
    this._workflowSvc
      .saveRegistrationNote({
        notes: this.manualNote,
        createdBy: '',
        modifiedBy: '',
        createdOn: new Date(),
        registrationID: this.registration.id
      })
      .subscribe(
        () => {
          // if loading the note was successful, reload the registration to get the updated data
          this.loadRegistration().subscribe(() => {
            this._toastrSvc.success('Note saved');
            this.manualNote = '';
          });
        },
        err => {
          this._toastrSvc.error(
            'Your note was not saved.',
            'Something went wrong!'
          );
        }
      );
  }

  cancelNote(noteID) {
    this.manualNote = ''; // discard the note
  }

  saveNotes() {}
}

import {
  ButtonStyle,
  ModalConfirmComponent
} from './../../../../system/modal-confirm/modal-confirm.component';
import {
  ShareAccessPermission,
  SharedAcessActivity
} from './../../../../../models/shared-access-details';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChange,
  AfterViewInit,
  ElementRef,
  ViewContainerRef,
  ViewChild,
  AfterViewChecked,
  OnChanges,
  forwardRef,
  Inject
} from '@angular/core';
import { Activity, ActivityModel } from '../../../../../models/activities';
import { Role, Actions, WorkflowApplication } from '../../../../../models';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import {
  WorkflowContextService,
  SecurityService,
  DataService
} from '../../../../../services';
import { from, Observable, OperatorFunction } from 'rxjs';
import { Router } from '@angular/router';
import {
  faShare,
  faShareAlt,
  faShareAltSquare
} from '@fortawesome/free-solid-svg-icons';
import { SharedAccessDetails } from 'src/app/models/shared-access-details';
import { SharePermissionsPipe } from 'src/app/pipes/share-permission.pipe';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { map } from 'lodash';
import { ActivityViewComponent } from '../activity-view/activity-view.component';

enum SharingStatus {
  Loading,
  Loaded,
  Sharing,
  Shared
}
@Component({
  selector: 'wm-activity-container',
  templateUrl: './activity-container.component.html',
  styleUrls: ['./activity-container.component.css']
})
export class ActivityContainerComponent implements OnInit, OnChanges {
  @Input() activity: Activity<ActivityModel>;
  @Input() isPreview: boolean;
  @Input() isTestApplication: boolean;
  @Input() showTitle = true;
  @Output() value$: EventEmitter<any> = new EventEmitter<any>();
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelled: EventEmitter<any> = new EventEmitter<any>();
  @Output() committed: EventEmitter<any> = new EventEmitter<any>();
  @Output() goPrevious: EventEmitter<any> = new EventEmitter<any>();
  @Output() goNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() switchApplication: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Output() navigated: EventEmitter<string> = new EventEmitter<string>();
  @Output() onShouldShowNext: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() onShouldShowPrevious: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() onShouldShowCancel: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() onShouldShowSave: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() onReloadApp: EventEmitter<void> = new EventEmitter<void>();

  @Output() viewTitle$: EventEmitter<string> = new EventEmitter<string>();
  @Output() onViewDesigner: EventEmitter<
    Activity<ActivityModel>
  > = new EventEmitter();

  @Input() applicationId: string;
  @Input() role: Role;
  @Input() form: UntypedFormGroup;
  @ViewChild('shareApplicationAccess', { static: false })
  shareAccessModal: ModalConfirmComponent;
  @ViewChild('activityView', { static: false })
  activityViewCtl: ActivityViewComponent;

  faShare = faShareAlt;

  canViewComponent = true;
  canEditData = true;
  checkingPermissions = false;
  roleActionEntitled: { [key: string]: boolean } = {};
  //shouldShowCancel = true;
  //shouldShowSave = true;
  //shouldShowNext = true;
  //shouldShowPrevious = true;
  //canUseCancel = true;
  //canUseSave = true;
  //canUsePrevious = true;
  //canUseNext = true;
  isLastActivity = false;
  processing = false;
  viewTitle: string;
  sharingStatus: SharingStatus = SharingStatus.Loading;
  SharingStatus = SharingStatus;
  shareForm: UntypedFormGroup;
  showCommandButton = true;
  cancelText = 'Cancel';
  canViewDesign = false;

  constructor(
    @Inject(forwardRef(() => WorkflowContextService))
    public context: WorkflowContextService,
    private _fb: UntypedFormBuilder,
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService, // , private _el: ElementRef
    private _router: Router,
    @Inject(forwardRef(() => DataService)) private _ds: DataService
  ) {}

  get activityViewLoaded(): boolean {
    return this.activityViewCtl != null && this.activityViewCtl.instanceLoaded;
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if (changes['role']) {
      this.canView();
    }
  }

  canView() {
    const self = this;

    self.roleActionEntitled = {};
  }

  cancel() {
    this._ds.cancelApplications(this.context.applicationId).subscribe(o => {
      if (o) {
        this._router.navigate(['/', 'home']).then(
          nav => {},
          err => {
            console.error(err); // when there's an error
          }
        );
      }
    });
  }
  showNextChanged(e) {
    this.onShouldShowNext.emit(e);
  }
  navigate(activityId: string) {
    this.processing = true;
    this.navigated.emit(activityId);
  }
  next() {
    this.processing = true;
    this.committed.emit(this.activity);
  }
  async persistChildComponent() {
    return this.activityViewCtl.persistChildComponent();
  }

  save() {
    this.saved.emit(this.activity);
  }

  previous() {
    this.processing = true;
    this.goPrevious.emit(this.activity);
  }

  async ngOnInit() {
    this.canViewDesign = await this._securitySvc
      .isLoginEntitled(Actions.DO_ANYTHING)
      .toPromise();
  }

  setViewTitle(e: string) {
    this.viewTitle$.next(e);
    this.viewTitle = e;
  }

  details: SharedAccessDetails = null;
  ButtonStyle = ButtonStyle;

  filteredPriorActivities: SharedAcessActivity[];
  filteredFutureActivities: SharedAcessActivity[];

  searchActivities(e: any) {
    const text = e.srcElement.value.toLowerCase();

    if (text) {
      const futureSrc =
        this.filteredFutureActivities.length > 0
          ? this.filteredFutureActivities
          : this.details.futureActivities;
      const priorSrc =
        this.filteredFutureActivities.length > 0
          ? this.filteredPriorActivities
          : this.details.priorActivities;

      this.filteredFutureActivities = futureSrc.filter(
        a => a.activityName.toLowerCase().indexOf(text) > -1
      );
      this.filteredPriorActivities = priorSrc.filter(
        a => a.activityName.toLowerCase().indexOf(text) > -1
      );
    } else {
      this.filteredFutureActivities = this.details.futureActivities;
      this.filteredPriorActivities = this.details.priorActivities;
    }
  }

  getShareApplicationDetails() {
    this.shareForm = new UntypedFormGroup({
      emailAddress: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ]),
      loaded: new UntypedFormControl('', Validators.required)
    });

    this.cancelText = 'Cancel';
    this.showCommandButton = true;

    this.sharingStatus = SharingStatus.Loading;
    this._ds
      .getWorkflowApplicationShareDetails(this.applicationId, this.activity)
      .subscribe(details => {
        this.sharingStatus = SharingStatus.Loaded;
        if (details.futureActivities) {
          details.futureActivities = details.futureActivities.filter(a =>
            this.canShareView(a)
          );
          this.filteredFutureActivities = details.futureActivities;
        }
        if (details.priorActivities) {
          details.priorActivities = details.priorActivities.filter(a =>
            this.canShareView(a)
          );
          this.filteredPriorActivities = details.priorActivities;
        }

        this.details = details;
        this.shareForm.controls['loaded'].setValue('true');
      });
  }

  showActivities(share: SharedAccessDetails): boolean {
    share.showDetails = !share.showDetails;
    if (!share.activities) {
      this._ds.getShareActivities(share.id).subscribe(activities => {
        share.activities = activities || [];
      });
    }
    return false;
  }

  removeSharedAccess(e: string) {
    this._ds.revokeSharedAccess(e).subscribe(() => {
      this.details.shares = this.details.shares.filter(s => s.id !== e);
    });
  }

  shareApplication() {
    this.sharingStatus = SharingStatus.Sharing;
    this._ds.shareWorkflowApplication(this.details).subscribe(() => {
      this.sharingStatus = SharingStatus.Shared;
      this.showCommandButton = false;
      this.cancelText = 'Close';

      // setTimeout(() => {
      //   this.shareAccessModal.cancel();
      // }, 3000);
    });
  }

  canShareView(a: SharedAcessActivity) {
    return a.availablePermissions.indexOf(ShareAccessPermission.ReadOnly) > -1;
  }

  canEdit(a: SharedAcessActivity) {
    return a.availablePermissions.indexOf(ShareAccessPermission.Edit) > -1;
  }

  openWorkflowDesign(activity: Activity<ActivityModel>) {
    this.onViewDesigner.emit(activity);
  }

  reloadApp() {
    this.onReloadApp.emit();
  }
}

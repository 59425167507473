import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataEntityLayoutModel } from 'src/app/models/activities';
import { DataEntityFactory } from 'src/app/services';

@Component({
  selector: 'wm-renewal-custom-field-list',
  templateUrl: './renewal-custom-field-list.component.html',
  styleUrls: ['./renewal-custom-field-list.component.css']
})
export class RenewalCustomFieldListComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() customFields: {
    createdBy: string;
    createdOn: string;
    dataEntityType: string;
    fieldValue: string;
    modifiedBy: string;
    modifiedOn: string;
    templateCode: string;
    label: string;
  }[];
  @Input() isReadOnly = false;

  customDataEntities: DataEntityLayoutModel[];

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['customFields']) {
      if (this.customFields) {
        this.customDataEntities = this.customFields.sort().map((field, index) => {
          if (this.form) {
            if (!this.form.controls[field.templateCode]) {
              this.form.addControl(
                field.templateCode,
                this._fb.control('', Validators.nullValidator)
              );
            }
          }
          const de = DataEntityFactory.createDataEntity(field.dataEntityType, {
            label: field.label,
            value: field.fieldValue,
            templateCode: field.templateCode
          });

          return new DataEntityLayoutModel({
            entity: de,
            displayOrder: index
          });
        });
      }
    }
  }

  persistValues(): {
    createdBy: string;
    createdOn: string;
    dataEntityType: string;
    fieldValue: string;
    modifiedBy: string;
    modifiedOn: string;
    templateCode: string;
    label: string;
  }[] {
    return this.customDataEntities.map(de => {
      const field = this.customFields.find(
        f => f.templateCode === de.entity.templateCode
      );

      if (field) {
        if(de.entity.value instanceof Object) {
          field.fieldValue = JSON.stringify(de.entity.value);
        } else {
        field.fieldValue = de.entity.value;
        }
      }

      return field;
    });
  }

  ngOnInit() {

  }
}

<form [formGroup]="form" *ngIf="config && form">
  <div class="form-group">
    <label class="bmd-label-floating" for="apiAccessId">
      API Access Key
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="apiAccessId"
      name="apiAccessId"
      [(ngModel)]="config.apiAccessId"
    />
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="apiSecureKey">
      API Secure Key
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="apiSecureKey"
      name="apiSecureKey"
      [(ngModel)]="config.apiSecureKey"
    />
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="locationId">
      Location Id
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="locationId"
      name="locationId"
      [(ngModel)]="config.locationId"
    />
  </div>
</form>

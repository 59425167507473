<wm-filter-builder
  id="reportId"
  [params]="params"
  (filtersChange)="filtersChangedHandler($event)"
  [savedFilters]="true"
  [showSimpleSearch]="false"
></wm-filter-builder>
<ng-container *ngIf="requiredFilterInput">
  <wm-control-message
    *ngFor="let f of requiredFilterInput"
    [prefix]="(f.label || f.name) + ' Filter is '"
    [control]="reqGrp.controls[f.name]"
  ></wm-control-message>
</ng-container>

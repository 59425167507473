import { CertifiedPaymentsPaymentProcessorConfiguration } from './../../../../models/payment-providers/certifiedpayments/certifiedpayments-payment-processor-configuration';
import { PaymentAccountConfigCertifiedpaymentsComponent } from './../../../../components/payment-account/payment-account-config-certifiedpayments/payment-account-config-certifiedpayments.component';
import { SquarePaymentProcessorConfiguration } from './../../../../models/payment-providers/square/square-payment-processor-configuration';
import { MunicipayPaymentProcessorConfiguration } from './../../../../models/payment-providers/municipay/municipay-payment-processor-configuration';
import { GovPayPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/govpay/govpay-payment-processor-account-configuration';
import { PSNPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/psn/psn-payment-processor-account-configuration';
import { PaymentAccountConfigSquareComponent } from './../../../../components/payment-account/payment-account-config-square/payment-account-config-square.component';
import { PaymentAccountConfigMunicipayComponent } from './../../../../components/payment-account/payment-account-config-municipay/payment-account-config-municipay.component';
import { PaymentAccountConfigGovPayComponent } from './../../../../components/payment-account/payment-account-config-govpay/payment-account-config-govpay.component';
import { PaymentAccountConfigPsnComponent } from './../../../../components/payment-account/payment-account-config-psn/payment-account-config-psn.component';
import { eGOVPaymentProcessorConfiguration } from './../../../../models/payment-providers/egov/egov-payment-processor-configuration';
import { PaymentAccountConfigeGOVComponent } from './../../../../components/payment-account/payment-account-config-egov/payment-account-config-egov.component';
import { FortePaymentProcessorAccountConfiguration } from './../../../../models/payment-providers/forte/forte-payment-processor-account-configuration';
import { PaymentAccountConfigForteComponent } from './../../../../components/payment-account/payment-account-config-forte/payment-account-config-forte.component';
import { KanPayPaymentProcessorConfiguration } from './../../../../models/payment-providers/kanpay/kanpay-payment-processor-configuration';
import { PaymentAccountConfigKanpayComponent } from './../../../../components/payment-account/payment-account-config-kanpay/payment-account-config-kanpay.component';
import { Subscription } from 'rxjs';
import { PointNPayPaymentProcessorConfiguration } from './../../../../models/payment-providers/pointnpay/pointnpay-payment-processor-configuration';
import { PaymentAccountConfigPointnpayComponent } from './../../../../components/payment-account/payment-account-config-pointnpay/payment-account-config-pointnpay.component';
import { SecurityService } from './../../../../services/security.service';
import { NmiPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/nmi/nmi-payment-processor-account-configuration';
import { PaymentAccountConfigNmiComponent } from './../../../../components/payment-account/payment-account-config-nmi/payment-account-config-nmi.component';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  Type,
  ComponentRef,
  ChangeDetectorRef
} from '@angular/core';
import { Location } from '@angular/common';
import {
  PaymentAccount,
  PaymentProcessorType,
  PayGovPaymentProcessorAccountConfiguration,
  PaymentProcessorAccountConfigurationBase,
  PaymentProcessor,
  Actions
} from '../../../../models';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  SystemService,
  ClientService,
  WorkflowContextService
} from '../../../../services';
import { PaymentAccountConfigPaygovComponent } from '../../../../components/payment-account/payment-account-config-paygov/payment-account-config-paygov.component';
import { PaymentAccountConfigBaseComponent } from '../../../../components/payment-account/payment-account-config-base/payment-account-config-base.component';
import { PaymentAccountConfigVpsComponent } from '../../../../components/payment-account/payment-account-config-vps/payment-account-config-vps.component';
import { PaymentAccountConfigSecurepayComponent } from '../../../../components/payment-account/payment-account-config-securepay/payment-account-config-securepay.component';
import { VpsPaymentProcessorAccountConfiguration } from '../../../../models/payment-providers/vps/vps-payment-processor-account-configuration';
import { SecurepayPaymentProcessorAccountConfiguration } from '../../../../models/payment-providers/securepay/securepay-payment-processor-account-configuration';
import { PaymentAccountConfigCardpointeComponent } from '../../../../components/payment-account/payment-account-config-cardpointe/payment-account-config-cardpointe.component';
import { CardpointePaymentProcessorAccountConfiguration } from '../../../../models/payment-providers/cardpointe/cardpointe-payment-processor-account-configuration';
import { PaymentAccountConfigOfficialpaymentsComponent } from '../../../../components/payment-account/payment-account-config-officialpayments/payment-account-config-officialpayments.component';
import { OfficialpaymentsPaymentProcessorAccountConfiguration } from '../../../../models/payment-providers/officialpayments/officialpayments-payment-processor-account-configuration';
import { PaymentAccountConfigPaymentStubComponent } from 'src/app/components/payment-account/payment-account-config-payment-stub/payment-account-config-payment-stub.component';
import { ToastrService } from 'ngx-toastr';
import { PaymentAccountConfigForteCheckoutComponent } from 'src/app/components/payment-account/payment-account-config-forte-checkout/payment-account-config-forte-checkout.component';

@Component({
  selector: 'wm-payment-account-detail-view',
  templateUrl: './payment-account-detail-view.component.html',
  styleUrls: ['./payment-account-detail-view.component.css']
})
export class PaymentAccountDetailViewComponent implements OnInit, OnDestroy {
  @ViewChild('prodProviderConfigView', {
    read: ViewContainerRef,
    static: false
  })
  prodItemViewContainer: ViewContainerRef;
  @ViewChild('testProviderConfigView', {
    read: ViewContainerRef,
    static: false
  })
  testItemViewContainer: ViewContainerRef;

  accountId: string;
  account: PaymentAccount;
  form: UntypedFormGroup;
  testForm: UntypedFormGroup;
  prodForm: UntypedFormGroup;
  configComponents: any;
  clientId: string;
  configTypes: any;
  prodComponentRef: ComponentRef<PaymentAccountConfigBaseComponent>;
  testComponentRef: ComponentRef<PaymentAccountConfigBaseComponent>;
  isProcessorConfigured: boolean;
  canEditProcessor: boolean;

  private clientSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private _context: WorkflowContextService,
    private _systemSvc: SystemService,
    private _clientSvc: ClientService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private fb: UntypedFormBuilder,
    private _location: Location,
    private ref: ChangeDetectorRef,
    private _toastr: ToastrService,
    private _securitySvc: SecurityService
  ) {
    this.testForm = fb.group({});
    this.prodForm = fb.group({});

    this.form = fb.group({
      testForm: this.testForm,
      prodForm: this.prodForm,
      accountName: ['', Validators.required],
      paymentProcessor: ['', Validators.required]
    });

    // Setup the component types for each processor type
    this.configComponents = {};

    this.configComponents[
      PaymentProcessorType.PayGov
    ] = PaymentAccountConfigPaygovComponent;
    this.configComponents[
      PaymentProcessorType.VPS
    ] = PaymentAccountConfigVpsComponent;
    this.configComponents[
      PaymentProcessorType.SecurePay
    ] = PaymentAccountConfigSecurepayComponent;
    this.configComponents[
      PaymentProcessorType.Cardpointe
    ] = PaymentAccountConfigCardpointeComponent;
    this.configComponents[
      PaymentProcessorType.OfficialPayments
    ] = PaymentAccountConfigOfficialpaymentsComponent;
    this.configComponents[
      PaymentProcessorType.PaymentStub
    ] = PaymentAccountConfigPaymentStubComponent;
    this.configComponents[
      PaymentProcessorType.NMI
    ] = PaymentAccountConfigNmiComponent;
    this.configComponents[
      PaymentProcessorType.PSN
    ] = PaymentAccountConfigPsnComponent;
    this.configComponents[
      PaymentProcessorType.Forte
    ] = PaymentAccountConfigForteComponent;
    this.configComponents[
      PaymentProcessorType.GovPay
    ] = PaymentAccountConfigGovPayComponent;
    this.configComponents[
      PaymentProcessorType.PointNPay
    ] = PaymentAccountConfigPointnpayComponent;
    this.configComponents[
      PaymentProcessorType.KanPay
    ] = PaymentAccountConfigKanpayComponent;
    this.configComponents[
      PaymentProcessorType.Municipay
    ] = PaymentAccountConfigMunicipayComponent;
    this.configComponents[
      PaymentProcessorType.Square
    ] = PaymentAccountConfigSquareComponent;
    this.configComponents[
      PaymentProcessorType.eGOV
    ] = PaymentAccountConfigeGOVComponent;
    this.configComponents[
      PaymentProcessorType.CertifiedPayments
    ] = PaymentAccountConfigCertifiedpaymentsComponent;
    this.configComponents[
      PaymentProcessorType.ForteCheckout
    ] = PaymentAccountConfigForteCheckoutComponent;

    this.configTypes = {};
    this.configTypes[
      PaymentProcessorType.PayGov
    ] = PayGovPaymentProcessorAccountConfiguration;
    this.configTypes[
      PaymentProcessorType.VPS
    ] = VpsPaymentProcessorAccountConfiguration;
    this.configTypes[
      PaymentProcessorType.SecurePay
    ] = SecurepayPaymentProcessorAccountConfiguration;
    this.configTypes[
      PaymentProcessorType.Cardpointe
    ] = CardpointePaymentProcessorAccountConfiguration;
    this.configTypes[
      PaymentProcessorType.OfficialPayments
    ] = OfficialpaymentsPaymentProcessorAccountConfiguration;
    this.configTypes[
      PaymentProcessorType.NMI
    ] = NmiPaymentProcessorAccountConfiguration;
    this.configTypes[
      PaymentProcessorType.PSN
    ] = PSNPaymentProcessorAccountConfiguration;
    this.configTypes[
      PaymentProcessorType.Forte
    ] = FortePaymentProcessorAccountConfiguration;
    this.configTypes[
      PaymentProcessorType.GovPay
    ] = GovPayPaymentProcessorAccountConfiguration;
    this.configTypes[
      PaymentProcessorType.PointNPay
    ] = PointNPayPaymentProcessorConfiguration;
    this.configTypes[
      PaymentProcessorType.KanPay
    ] = KanPayPaymentProcessorConfiguration;
    this.configTypes[
      PaymentProcessorType.Municipay
    ] = MunicipayPaymentProcessorConfiguration;
    this.configTypes[
      PaymentProcessorType.Square
    ] = SquarePaymentProcessorConfiguration;
    this.configTypes[
      PaymentProcessorType.eGOV
    ] = eGOVPaymentProcessorConfiguration;
    this.configTypes[
      PaymentProcessorType.CertifiedPayments
    ] = CertifiedPaymentsPaymentProcessorConfiguration;
  }

  toggleTestMode() {
    this.account.inTestMode = !this.account.inTestMode;
    this.save(false);
  }

  toggleEnabled() {
    if (this.account.isEnabled) {
      this._systemSvc
        .disablePaymentAccount(this.account.id)
        .subscribe(result => {
          this.account.isEnabled = false;
          this._toastr.success('Payment Account Disabled');
        });
    } else {
      this._systemSvc
        .enablePaymentAccount(this.account.id)
        .subscribe(result => {
          this.account.isEnabled = true;
          this._toastr.success('Payment Account Enabled');
        });
    }
  }

  hasControls(form: UntypedFormGroup) {
    const validators = Object.keys(form.controls);

    return validators.length > 0;
  }
  async ngOnInit() {
    this.accountId = this.route.snapshot.params.paymentAccountId;

    if (this.accountId) {
      const account: PaymentAccount = await this._systemSvc
        .getPaymentAccount(this.accountId)
        .toPromise();
      const payType: PaymentProcessorType = account.processor
        .type as PaymentProcessorType;
      this.account = account;
      this.loadComponent(payType);
    } else {
      const init = () => {
        this.account = new PaymentAccount({
          clientId: this._context.client.id
        });
        this.account.client = this._context.client;

        this.form.updateValueAndValidity();
        this.ref.detectChanges();
      };
      if (this._context.client) {
        init();
      } else {
        this.clientSubscription = this._context.client$.subscribe(() => {
          init();
        });
      }
    }
    this._securitySvc
      .isLoginEntitled(Actions.PAYMENT_PROCESSOR_MANAGE)
      .subscribe(entitled => {
        this.canEditProcessor = entitled;
      });
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  toJSON(obj: any): string {
    return JSON.stringify(obj);
  }
  loadComponent(type: PaymentProcessorType) {
    let compType: Type<PaymentAccountConfigBaseComponent>;

    this.isProcessorConfigured =
      this.account.processor && this.account.processor.configuration
        ? this.account.processor.configuration.isConfigured
        : false;

    if (this.configComponents[type]) {
      compType = this.configComponents[type];
    }

    if (compType && this.prodItemViewContainer) {
      this.prodItemViewContainer.clear();

      this.prodComponentRef = this.prodItemViewContainer.createComponent<
        PaymentAccountConfigBaseComponent
      >(compType);
      this.prodComponentRef.instance.config = this.account.providerAccountSettings;
      this.prodComponentRef.instance.form = this.prodForm;
      this.prodComponentRef.instance.account = this.account;
    }

    if (this.configComponents[type]) {
      compType = this.configComponents[type];
    }

    if (compType && this.testItemViewContainer) {
      this.testItemViewContainer.clear();
      this.testComponentRef = this.testItemViewContainer.createComponent<
        PaymentAccountConfigBaseComponent
      >(compType);
      this.testComponentRef.instance.config = this.account.testProviderAccountSettings;
      this.testComponentRef.instance.form = this.testForm;
      this.testComponentRef.instance.account = this.account;
    }

    this.form.updateValueAndValidity();
    this.ref.detectChanges();
  }

  processorChanged(e) {
    if (e) {
      this.account.processor = e;
      this.account.processorId = e.id;

      // clear the validators and allow them to be re-populated as appropriate
      this.prodForm = this.fb.group({});
      this.testForm = this.fb.group({});

      if (this.prodItemViewContainer) {
        this.prodItemViewContainer.clear();
      }
      if (this.testItemViewContainer) {
        this.testItemViewContainer.clear();
      }

      if (this.configTypes[e.type]) {
        this.account.providerAccountSettings = new this.configTypes[e.type]();
        this.account.testProviderAccountSettings = new this.configTypes[
          e.type
        ]();
      } else {
        this.account.providerAccountSettings = new PaymentProcessorAccountConfigurationBase();
        this.account.testProviderAccountSettings = new PaymentProcessorAccountConfigurationBase();
      }
    } else {
      this.prodItemViewContainer.clear();
      this.account.processor = new PaymentProcessor();
      this.account.providerAccountSettings = null;
      this.account.testProviderAccountSettings = null;
    }

    this.loadComponent(e.type);
  }

  goBack() {
    this._location.back();
  }

  save(redirect: boolean = true) {
    this._systemSvc.savePaymentAccount(this.account).subscribe(e => {
      if (redirect) {
        this.goBack();
      } else {
        this._toastr.success('Saved');
      }
    });
  }
}

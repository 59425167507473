import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'wm-help-view',
  templateUrl: './help-view.component.html',
  styleUrl: './help-view.component.css'
})
export class HelpViewComponent implements OnInit {
  userGuideLink: any;
  trainingSiteLink = 'https://geopermitstraining.hubs.vidyard.com/'

  constructor(
    private _dataSvc: DataService
  ) {}

  ngOnInit() {
    this.userGuideLink = '';

    this._dataSvc.getUserGuideLink().subscribe(results => {
      this.userGuideLink = results;
    });
  }
}

import * as Workflow from 'esri/widgets/Editor/Workflow';
import { DataEntity } from './data-entities';

export class Requirement {
  id: string;
  workflowId: string;
  workflow: Workflow;
  isEditing: boolean;
  label: string;
  templateCode: string;
  dataEntityDefinition: DataEntity;

  constructor(options?: Partial<Requirement>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Utilities } from './../../../services/utilities/index';
import {
  WaitActivity,
  WaitActivityModel
} from './../../../models/activities/wait-activity';
import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  Inject,
  forwardRef,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  WorkflowService,
  WorkflowContextService,
  ValidationService,
  SecurityService
} from '../../../services';
import {
  Activity,
  ActivityModel,
  ActivityStatus
} from '../../../models/activities';
import { Router } from '@angular/router';
import {
  Role,
  Client,
  ApplicationProcessingStatus,
  FeedbackMessageType
} from '../../../models';
import { UntypedFormGroup } from '@angular/forms';
import {
  Subscription,
  Observable,
  EMPTY,
  Subscriber,
  throwError,
  of
} from 'rxjs';
import { ActivityNavigationInfo } from '../../../models/activity-navigation-Info';
import { ActivityContainerComponent } from '../../../components/workflow/activities/activity-editor/activity-container/activity-container.component';
import { JsonNetDecycle } from '../../../services/utilities/json-net-decycle';
import { catchError, map } from 'rxjs/operators';
import { FeeDataEntity } from 'src/app/models/data-entities';
import { ToastrService } from 'ngx-toastr';
import { get } from 'jquery';
import { SaveActivityResponse } from 'src/app/models/save-activity-response';

@Component({
  selector: 'wm-application-input',
  templateUrl: './application-input.component.html',
  styleUrls: ['./application-input.component.css']
})
export class ApplicationInputComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() applicationId: string;
  @Input() workflowId: string;
  @Input() activity: Activity<ActivityModel>;
  form: UntypedFormGroup;
  @Input() isTestApplication: boolean;
  @Input() shouldShowSave: boolean;
  @Input() isLastActivity: boolean;
  statusMessage: string;
  testRoles: Role[];
  selectedRole: string;
  roleSubscription: Subscription;
  isNavigating: boolean;
  isDestroyed = false;
  workflowVersionId: string;

  @Output() switchApplication: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Output() onActivityChanged: EventEmitter<
    Activity<ActivityModel>
  > = new EventEmitter<Activity<ActivityModel>>();
  @Output() onIsCompleteChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() onShowPreviousChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() onShowNextChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() onShowCancelChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() onShowSaveChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() CanVoidDiscardChanged: EventEmitter<{
    canVoid: boolean;
    canDiscard: boolean;
  }> = new EventEmitter<{ canVoid: boolean; canDiscard: boolean }>();

  @Output() onNavigationChanged: EventEmitter<
    ActivityNavigationInfo[]
  > = new EventEmitter<ActivityNavigationInfo[]>();
  @Output() onFeesChanged: EventEmitter<FeeDataEntity[]> = new EventEmitter<
    FeeDataEntity[]
  >();
  @Output() onHasFeesChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() loadApplication: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() applicationIsProcessing: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  @ViewChild(ActivityContainerComponent, { static: false })
  activityContainer: ActivityContainerComponent;
  canVoid = false;
  isProcessing = false;
  applicationProcessing = false;

  idleAfterProc: Subscription;
  idleAfterNav: Subscription;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    public context: WorkflowContextService,
    private _router: Router,
    private _ref: ChangeDetectorRef,
    private _toastr: ToastrService,
    @Inject(forwardRef(() => SecurityService))
    private _securityService: SecurityService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['applicationId']) {
      this.applicationId = changes['applicationId'].currentValue;
    }
  }

  get activityContainerLoaded(): boolean {
    return this.activityContainer != null && this.activityContainer.activityViewLoaded;
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    this._ref.detach();

    if (this.idleAfterNav) {
      this.idleAfterNav.unsubscribe();
    }

    if (this.idleAfterProc) {
      this.idleAfterProc.unsubscribe();
    }
  }

  async persistChildComponent() {
    return this.activityContainer.persistChildComponent();
  }

  detectChanges() {
    if (!this.isDestroyed) {
      this._ref.detectChanges();
    }
  }
  exitTestApplication() {
    this._workflowSvc
      .cleanupTestApplication(this.context.applicationId)
      .subscribe(a => {
        this.context.applicationId$.emit(null);
        if (this.roleSubscription) {
          this.roleSubscription.unsubscribe();
          this.roleSubscription = null;
        }
        this.context.viewAsRole$.emit(null);
        this._router.navigate([
          '/full/workflow-editor/diagram',
          this.context.workflow.id
        ]);
      });

    return false;
  }

  changeRole(e) {
    this.activity = null;
    this.context.viewAsRole$.emit(
      this.testRoles.find(r => r.id === this.selectedRole)
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  updateStatus(status: string) {
    this.statusMessage = status;
    setTimeout(() => {
      this.statusMessage = null;
    }, 2000);
  }

  refreshApp() {
    this.loadApplication.emit(true);
  }

  loadActivity(activity: Activity<ActivityModel>) {
    if (activity) {
      this.form = new UntypedFormGroup(
        ValidationService.createValidationGroup(activity),
        {
          updateOn: 'blur'
        }
      );

      this.activity = activity;
      this.onActivityChanged.emit(this.activity);

      this.onIsCompleteChanged.emit(
        this.activity.type === WorkflowService.ACTIVITIES.CompleteWorkflow
      );
      this.detectChanges();
      this.CanVoidDiscardChanged.emit({
        canVoid: this.canVoid,
        canDiscard: this.activity.canDiscardApplication
      });

      // if there is a feedbackMessage at this point, it is because the activity was loaded, then something in another session
      // canceled the activity the user was already on, and after that processed finished, then the user tried to hit Next or Update on the activity
      // or a contractor registration had an invalid expiration date.
      if (activity.feedbackMessage) {
        switch (activity.feedbackMessageType) {
          case FeedbackMessageType.Error: {
            this._toastr.error(
              activity.feedbackMessage,
              activity.feedbackTitle || 'Activity unavailable.',
              { disableTimeOut: true }
            );
            break;
          }
          case FeedbackMessageType.Warning: {
            this._toastr.warning(
              activity.feedbackMessage,
              activity.feedbackTitle || 'Activity Warning.',
              { disableTimeOut: true }
            );
            break;
          }
          case FeedbackMessageType.Success: {
            this._toastr.success(
              activity.feedbackMessage,
              activity.feedbackTitle || 'Activity Success.',
              { disableTimeOut: true }
            );
            break;
          }
          default: {
            this._toastr.info(
              activity.feedbackMessage,
              activity.feedbackTitle || 'Activity unavailable.',
              { disableTimeOut: true }
            );
            break;
          }
        }
      }
    } else {
      // navigate to the application summary for a complete application
      const navOptions: string[] = [
        '/application/workflow-application-summary',
        this.applicationId
      ];
      if (this.isTestApplication) {
        navOptions.push('true');
      }
      this._router.navigate(navOptions);
    }
  }

  viewDesigner(activity: Activity<ActivityModel>) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(
        [
          '/admin/workflow-builder',
          this.workflowId,
          this.workflowVersionId,
          this.applicationId
        ],
        {
          queryParams: { activityId: activity.id }
        }
      )
    );

    window.open(url, '_blank');
  }

  loadCurrentActivity(): Observable<{
    activity: Activity<ActivityModel>;
    workflowId: string;
    workflowVersionId: string;
    applicationNumber: string;
    clientApplicationNumber: string;
    client: Client;
    isTestApplication: boolean;
    navigationItems: ActivityNavigationInfo[];
    fees: FeeDataEntity[];
    hasFees: boolean;
    submitterId: string;
    workflowName: string;
    isProcessing: boolean;
  }> {
    if (this.applicationId) {
      this.activity = null;
      return this._workflowSvc.getCurrentActivity(this.applicationId).pipe(
        map(result => {
          if (result && !result.isProcessing) {
            this.isProcessing = false;
            this.isTestApplication = result.isTestApplication;
            this.workflowId = result.workflowId;
            this.workflowVersionId = result.workflowVersionId;
            if (
              !this.context.client ||
              this.context.client.id !== result.client.id
            ) {
              this.context.client$.emit(result.client);
            }
            this.onFeesChanged.emit(result.fees);
            this.onHasFeesChanged.emit(result.hasFees);
            this.canVoid = result.canUserVoidApplication;
            this.loadActivity(result.activity);
          } else {
            this.isProcessing = true;
          }

          return result;
        })
      );
    } else {
      return EMPTY;
    }
  }

  goPrevious(activity) {
    this.activity = null;
    const decActivity = JsonNetDecycle.decycle(activity);
    this._workflowSvc
      .navigatePrevious(this.applicationId, decActivity)
      .subscribe(resp => {
        if (resp) {
          if (resp.activity) {
            this.loadActivity(resp.activity);
          } else if (!resp.canNavigate) {
            this._toastr.info(
              'This activity is not available at this point in the process.',
              'Activity Navigation'
            );
            this.loadActivity(activity);
          }
        }
      });
  }

  saved(activity: Activity<ActivityModel>): Observable<SaveActivityResponse> {
    this.activity = null;
    const decActivity = JsonNetDecycle.decycle(activity);
    return this._workflowSvc
      .saveWorkflowActivity(this.applicationId, decActivity)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  committed(activity) {
    const decActivity = JsonNetDecycle.decycle(activity);
    this.activity = null;

    return this._workflowSvc
      .submitWorkflowActivity(
        this.applicationId,
        decActivity,
        this.isNavigating
      )
      .pipe(
        map(a => {
          if (a) {
            if (a.nextActivity) {
              this.isNavigating =
                a.nextActivity.status === ActivityStatus.Completed;
            } else {
              this.isNavigating = false;
            }

            if (a.applicationId) {
              if (a.applicationId !== this.applicationId) {
                this.switchApplication.emit(a.applicationId);
              }
              this.applicationId = a.applicationId;
            }
            this.onShowNextChanged.emit(true); // this is here to reset the next button after activity is loaded

            if (a.nextActivity) {
              if (
                a.nextActivity.applicationProcessingStatus ===
                ApplicationProcessingStatus.Processing
              ) {
                this.waitForIdleApplicationAfterProcessRequest();
              }
            }

            this.loadActivity(a.nextActivity);
            this.onNavigationChanged.emit(a.navigationActivities);
            this.onFeesChanged.emit(a.fees);
            this.onHasFeesChanged.emit(a.hasFees);
          }

          return a;
        }),
        catchError(err => {
          this.onShowNextChanged.emit(true); // this is here to reset the next button after activity is loaded
          this.loadActivity(activity);
          return throwError(err);
        })
      );
  }

  navigated(activityDataId: string, submitAttempted = false) {
    this._workflowSvc
      .navigateToActivity(this.applicationId, activityDataId)
      .subscribe(resp => {
        const activity = resp.activity;
        const canNavigate = resp.canNavigate;

        // submitAttempted = did the user click Next or Update on an activity, but was stopped
        // because the app was already processing?
        if (submitAttempted) {
          if (activity) {
            this.loadActivity(activity);

            this._toastr.success(
              'Your progress was not saved. Be aware that the finished action also could have changed data on this activity. Please review this activity and click ' +
                (!this.shouldShowSave
                  ? 'Update'
                  : this.isLastActivity
                  ? 'Submit'
                  : 'Next') +
                ' when ready.',
              'The previous application action has finished.',
              { disableTimeOut: true }
            );
            // if activity is null, then it was canceled between the time the activity was loaded, and the time the activity
            // was re-navigated to after waiting for the application to finish processing
          } else {
            this.loadApplication.emit(true);

            this._toastr.info(
              'Because of another action taken in this application, the activity you attempted to submit is no longer available.',
              'Activity unavailable',
              { disableTimeOut: true }
            );
          }
        } else {
          if (activity) {
            if (
              activity &&
              activity.applicationProcessingStatus ===
                ApplicationProcessingStatus.Idle
            ) {
              this.activity = null;
              setTimeout(() => {
                this._ref.detectChanges();
                this.loadActivity(activity);
              });
            } else {
              this.waitForIdleApplication(activityDataId, activity);
            }
          } else {
            if (canNavigate) {
              // if activity is null, then it became unnavigable between the time the of the sidebar
              // being loaded and the time of clicking on the activity to navigate

              this.loadApplication.emit(true);

              this._toastr.info(
                'Because of another action taken in this application, the activity you attempted to load is no longer available.',
                'Activity unavailable.',
                { disableTimeOut: true }
              );
            } else {
              this._toastr.info(
                'This activity is not available at this point in the process.',
                'Activity Navigation'
              );
            }
          }
        }
        this.isNavigating = false;
      });
  }

  get showActivity() {
    return !this.isNavigating;
  }

  navigateToActivity(activityDataId: string, submitAttempted = false) {
    this.isNavigating = true;
    //this.activity = null;
    this.navigated(activityDataId, submitAttempted);
  }

  delay(time: number) {
    return new Promise(r => {
      setTimeout(r, time);
    });
  }

  waitForIdleApplicationAfterProcessRequest() {
    this.idleAfterProc = of(
      (async () => {
        this.applicationProcessing = true;
        this.applicationIsProcessing.emit(true);

        // give the user time to read the message on screen before checking to see if processing is finished
        await this.delay(6000);

        for (let i = 0; i < 15; i++) {
          if (
            !(await this._workflowSvc
              .isProcessing(this.applicationId)
              .toPromise())
          ) {
            this.applicationProcessing = false;
            this.applicationIsProcessing.emit(false);

            // need to navigate to activity at this point because the processing that held up the ProcessActivity request
            // could have changed data on this activity, or the activity could have since been canceled by the processing
            // that held it up
            this.navigateToActivity(this.activity.activityDataId, true);

            break;
          }

          await this.delay(2000);
        }

        if (this.applicationProcessing) {
          this._toastr.error(
            'The application has encountered a Processing Status error. Please contact support.',
            'Failed to submit activity.',
            { disableTimeOut: true }
          );
        }
      })()
    ).subscribe();
  }

  waitForIdleApplication(
    activityDataId: string,
    activity: Activity<ActivityModel>
  ) {
    this.idleAfterNav = of(
      (async () => {
        this.isProcessing = true;

        for (let i = 0; i < 15; i++) {
          await new Promise(r => {
            setTimeout(r, 2000);
          });

          if (
            !(await this._workflowSvc
              .isProcessing(this.applicationId)
              .toPromise())
          ) {
            this.isProcessing = false;

            // navigate again because the activity could have been canceled as part
            // of the processing that held up the initial navigation request
            this.navigateToActivity(activityDataId);

            break;
          }

          if (this.isProcessing) {
            this._toastr.error(
              'The application has encountered a Processing Status error. Please contact support.',
              'Failed to load activity.',
              { disableTimeOut: true }
            );
          }
        }
      })()
    ).subscribe();
  }
}

<div class="activity-sidebar" [ngStyle]="currentStyle">
  <div class="container py-4 d-flex flex-column">
    <div class="row mb-auto">
      <div class="col">
        <h5>{{ activityName ? activityName : 'Activity Title' }}</h5>

        <ng-template activity-editor-host></ng-template>

        <div>
          <h6>Validation</h6>

          <p *ngIf="saving">Currently saving activity...</p>
          <p *ngIf="isValidating" class="text-warning">
            Currently validating workflow...
          </p>
          <p
            class="text-success"
            *ngIf="
              !saving &&
              !isValidating &&
              !validationErrors &&
              deValidationErrors.length < 1
            "
          >
            This activity has no problems
          </p>

          <p class="text-warning" *ngIf="deValidationErrors.length > 0">
            <ng-container *ngIf="deValidationErrors.length > 1">
              {{ deValidationErrors.length }} data entities have problems
            </ng-container>
            <ng-container *ngIf="deValidationErrors.length === 1">
              1 data entity has problems
            </ng-container>
          </p>

          <ng-container *ngIf="validationErrors">
            <p class="text-danger">
              This activity has problems:
            </p>
            <wm-validation-message
              [error]="validationErrors"
            ></wm-validation-message
          ></ng-container>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="loaded">
      <div class="col">
        <button
          class="btn btn-raised btn-success"
          [disabled]="!form.valid || saving || _workflowSvc.designerVersionMode"
          (click)="saveClicked()"
        >
          <span *ngIf="!saving">Save</span>

          <span *ngIf="saving">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Saving...
          </span>
        </button>
        <button class="btn btn-raised btn-primary mx-2" (click)="preview()" [disabled]="(form && !form.valid) || validationErrors || (deValidationErrors && deValidationErrors.length > 0)">
          Preview
        </button>
        <button
          class="btn btn-raised btn-default float-right"
          (click)="close()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

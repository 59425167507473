
<div class="row">
    <div class="col">
        <h3>Help</h3>
    </div>
</div>

<div class="row">
    <div class="col">
        <ul class="list-group">
            <li class="list-group-item">
                <a 
                    [href]="userGuideLink"
                    target="_blank"
                    rel="noopener"
                >
                    User Guide
                </a>
            </li>
            <li class="list-group-item">
                <a 
                    [href]="trainingSiteLink"
                    target="_blank"
                    rel="noopener"
                >
                    Training Site
                </a>
            </li>
        </ul>
    </div>
</div>

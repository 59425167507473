import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services';

@Component({
  selector: 'wm-scheduled-email-tester',
  templateUrl: './scheduled-email-tester.component.html',
  styleUrl: './scheduled-email-tester.component.css'
})
export class ScheduledEmailTesterComponent {
  processRunning: boolean = false;

  constructor(private _dataSvc: DataService, private _toastr: ToastrService) {}

  processEmails() {
    this.processRunning = true;

    this._dataSvc.processScheduledEmails().subscribe(result => {
      this.processRunning = false;
    }, (e) => {
      this.processRunning = false;
      this._toastr.error("Process Failed", (e || {}).message);
    });
  }
}
